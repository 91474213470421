<script setup lang="ts">
import { AxiosError } from 'axios'
import { Tippy } from 'vue-tippy'
import type {
  CreatePostState,
  NovaModalPostCreateEmits,
  NovaModalPostCreateProps,
} from './NovaModalPostCreate.types'
import {
  type FileContainer,
  type FileImage,
  type FileVideo,
  // type ScopeCodeType,
  type EditorImage,
  type CreatePostPayload,
  type EditPostPayload,
  type CategoryCode,
  PostContentsType,
  type CreateTempPostPayload,
  useAppStore,
  CategorySN,
} from '@store'
import { WEEK } from '@configs'
import {
  NovaModalCommon,
  NovaModalDialog,
  NovaModalPostCreatePreview,
  NovaModalPostCreateTempSaveList,
  NovaBoxPostContentsSnapshotImage,
  NovaBoxPostContentsSnapshotVideo,
} from '#components'

const emit = defineEmits<NovaModalPostCreateEmits>()
const props = withDefaults(defineProps<NovaModalPostCreateProps>(), {
  imgOrVideoFile: null,
  source: null,
  createPremiumPost: false,
  openStatus: null,
})

const { gtEvent } = useGoogleTag()
const config = useRuntimeConfig()
const { dayjs } = useDayjs()
const { createPostType, imgOrVideoFile, createPremiumPost } = toRefs(props)
const appStore = useAppStore()
const userStore = useUserStore()
const postStore = usePostStore()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const { t, locale, messages } = useI18n()

const postCardsRef = ref<typeof NovaBoxPostContentsSnapshotImage>()
const postVideoRef = ref<typeof NovaBoxPostContentsSnapshotVideo>()

// models: 수정 모드 여부 모델
const isEditMode = computed(() => !!props.source)

// models: 공개 여부 목록(전체 공개, 나만 보기)
// const postScopes = computed(() =>
//   Object.keys(
//     (
//       messages.value[locale.value].postCreate as {
//         postScope: { [Property in ScopeCodeType]: string }
//       }
//     ).postScope
//   ).map((key) => ({ label: t(`postCreate.postScope.${key}`), value: key }))
// )

// models: 해시태그 목록
const hashtags = computed(() =>
  postStore.baseHashtags.map((hashtag) => hashtag.hashtagNm)
)

// models: 카테고리 목록
const categories = computed(() =>
  postStore.categories
    .filter((item) =>
      item.ctgryNttWritngAuthorCodeArray.includes(
        userStore.user?.unionUserType || ''
      )
    )
    .map((item) => ({
      label: item.nttCtgryNm,
      value: item.cmtyNttCtgrySn,
    }))
)

// models: 크리에이터 콘텐츠 카테고리 선택 여부
const isCreatorContents = computed(
  () => state.value.category === CategorySN.CREATOR_CONTENTS
)

// models: 카드형, 비디오형 등록 모델
const saveImgOrVideoFile = ref<FileContainer | null>(null)

// models: 미리보기, 저장 버튼 비활성화 모델
const isDisabled = ref(createPostType.value === 'CVOD')

// models: 미리보기, 저장 버튼 비활성화 모델
const isOpenStatus = ref(true)

// models: 포스트 발행 기본 모델
const defaultState: CreatePostState = {
  title: '',
  tags: [],
  tempSave: -1,
  category: -1,
  scope: 'PUBL',
  bodyData: '',
  tempImg: [],
  file: '',
  fileUrlList: [],
  isAIContents: false,
}

// models: 카테고리 변경시 포스트 작성 권한 모델
// const postCreateAvailable = ref<PostCreateAvailable>()
const isLoadingCategoryChange = ref(false)

// models: 포스트 발행 모델
const state = ref<CreatePostState>(defaultState)
const init = ref('')

// models: 최소 제목 글자수 충족 여부
// const isMinTitle = ref(false)

// models: 게시판 선택 여부
const isPostCreateAvailable = computed(() => state.value.category >= 0)

// models: 게시 재한 카테고리 모델
const publishedLimitCategory = computed(() =>
  postStore.categories.find((item) => item.ctgryNttWritngCoLmttAt === 'Y')
)

// models: 크리에이터 콘텐츠 카테고리 선택 여부 (미사용)
// const isPublishLimitCategory = computed(
//   () => state.value.category === publishedLimitCategory.value?.cmtyNttCtgrySn
// )

// models: 에디터 로딩
const editorLoading = ref(false)

// models: 에디터 플레이스 홀더
const editorPlaceholder = computed<CategoryCode>(() => {
  if (state.value.bodyData) {
    return ''
  }

  if (!isPostCreateAvailable.value) return 'requireCategory'

  const category = postStore.categories.find(
    (item) => item.cmtyNttCtgrySn === state.value.category
  )

  return category?.ctgryDfltWordsCode || ''
})

// methods: 포스트 발행 모델 초기화
const setInit = () => {
  // 1. 포스트 작성 모달: 수정
  if (isEditMode.value) {
    state.value = {
      title: props.source?.nttSj || '',
      tags: (props.source?.hashTagList || []).map((item) => item.hashtagNm),
      tempSave: -1,
      category: props.source?.cmtyNttCtgrySn || -1,
      scope: props.source?.nttOthbcScopeCode || 'PUBL',
      bodyData: props.source?.nttCn || '',
      tempImg: [],
      file: '',
      fileUrlList: getImagesFromContents(props.source?.nttCn || ''),
      isAIContents: props.source?.nttAiPrcuseAt === 'Y',
    }
    console.log(state.value)
    saveImgOrVideoFile.value = props.source?.imageOrVideoList
      ? { ...props.source.imageOrVideoList }
      : null

    // init
    const createAtTimestamp = new Date(
      dayjs.utc(props.source!.nttRegistDt).format()
    ).getTime()
    const nowTimestamp = new Date(dayjs.utc().format()).getTime()
    const gap = nowTimestamp - createAtTimestamp
    // console.log('gap', gap)
    // console.log('limit', MINUTE)
    isOpenStatus.value = gap < WEEK
  }

  // 2. 포스트 작성 모달: 신규
  else {
    saveImgOrVideoFile.value = {
      fileId: imgOrVideoFile.value?.fileId || '',
      images: imgOrVideoFile.value?.images || [],
      video: imgOrVideoFile.value?.video ? [imgOrVideoFile.value.video] : [],
    }
  }

  init.value = JSON.stringify({
    ...state.value,
    imgOrVideoFile: saveImgOrVideoFile.value,
  })
}

// methods: 포스트 발행 모델(카테고리) 업데이트
const handleOnUpdateCategory = (value: number) => {
  const label = categories.value.find((item) => item.value === value)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '포스트 작성 > 게시판 선택',
    eventLabel: label?.label || '',
    eventSlot: '',
    eventI18nAddr: useKoreanTranslation('creatorMainProfile.editMainProfile'),
    eventComponent: 'Button',
  })
  if (value !== publishedLimitCategory.value?.cmtyNttCtgrySn) {
    state.value.category = value
    return
  }
  try {
    isLoadingCategoryChange.value = true
    // postCreateAvailable.value = await postStore.fetchPostCreateAvailable({
    //   cmtyNttCtgrySn: value,
    // })
    state.value.category = value
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case 2011:
        case 2036:
          useToast(
            t(
              `postCreate.toastMessage.createPostUnavailable.${err.response.status}`
            )
          )
          break
        default:
          useToast(t('commonError.network.message'))
          break
      }
    }
  } finally {
    isLoadingCategoryChange.value = false
  }
}

// methods: 포스트 발행 모델(공개 여부) 업데이트
// const handleOnUpdateScope = (value: ScopeCodeType) => {
//   state.value.scope = value
// }

// methods: 포스트 발행 모델(해시테그) 업데이트
const updateTags = (tags: string[]) => {
  state.value.tags = tags
}

// methods: 임시저장
const handleOnTempSave = async () => {
  if (onValidate()) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '임시 저장',
      eventLabel: t('postCreate.button.tempSave'),
      eventSlot: 'Modal',
      eventI18nAddr: useKoreanTranslation('postCreate.button.tempSave'),
      eventComponent: 'Button',
    })
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })

      const payload: CreateTempPostPayload = {
        cmtyNttCtgrySn: state.value.category,
        nttOthbcScopeCode: state.value.scope,
        nttSj: state.value.title,
        nttCn: state.value.bodyData,
        nttTyCode: createPostType.value,
        fileId: saveImgOrVideoFile.value?.fileId || '',
        cardVideoJson: saveImgOrVideoFile.value
          ? JSON.stringify(saveImgOrVideoFile.value)
          : '',
        nttAiPrcuseAt: state.value.isAIContents ? 'Y' : 'N',
      }

      if (createPremiumPost.value) {
        delete payload.cmtyNttCtgrySn
      }

      await postStore.createTempPost(payload)
      init.value = JSON.stringify({
        ...state.value,
        imgOrVideoFile: saveImgOrVideoFile.value,
      })
      gtEvent('postAction', {
        eventCategory: '포스트',
        eventAction: '임시 저장',
        eventLabel: t('postCreate.button.tempSave'),
        eventSlot: 'Modal',
        eventI18nAddr: useKoreanTranslation('postCreate.button.tempSave'),
        eventComponent: 'Button',
        nttSj: payload.nttSj,
        nttCn: payload.nttCn,
      })
      useToast(t('postCreate.toastMessage.tempSave'))
    } catch {
      useToast(t('postCreate.toastMessage.errors.default'))
    } finally {
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }
}

// methods: 임시저장 목록 조회 팝업 열기
const openTempList = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '포스트 작정 > 임시저장 불러오기 클릭',
    eventLabel: t('postCreate.placeholder.tempSave'),
    eventSlot: 'Modal',
    eventI18nAddr: useKoreanTranslation('postCreate.placeholder.tempSave'),
    eventComponent: 'Button',
  })
  modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_POST_TEMP_LIST,
      showCloseBtn: true,
      hasInnerScroll: true,
    },
    on: {
      close: () => {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '포스트 작정 > 임시저장 목록 모달 > 닫기',
          eventLabel: t('postCreate.modal.tempTitle'),
          eventSlot: 'Modal',
          eventI18nAddr: useKoreanTranslation('postCreate.modal.tempTitle'),
          eventComponent: 'Button',
          creatorUserSn: userStore.user!.userSn,
        })
        modalHide(modalsName.MODAL_POST_TEMP_LIST)
      },
    },
    slots: {
      title: t('postCreate.modal.tempTitle'),
      noScrollContents: {
        component: NovaModalPostCreateTempSaveList,
        bind: {
          nttTyCode: createPostType.value,
        },
        on: {
          selectItem(id: number) {
            selectTempItem(id)
            modalHide(modalsName.MODAL_POST_TEMP_LIST)
          },
          close() {
            modalHide(modalsName.MODAL_POST_TEMP_LIST)
          },
        },
      },
    },
  })
}

// methods: 포스트 임시저장 불러오기
const selectTempItem = async (tempSN: number) => {
  try {
    const result = await postStore.fetchTempPost({ tmprNttSn: tempSN })
    state.value = {
      title: result.nttSj,
      tags: result.hashTagList,
      tempSave: result.tmprNttSn,
      category: result.cmtyNttCtgrySn,
      scope: result.nttOthbcScopeCode,
      bodyData: result.nttCn,
      tempImg: [],
      file: '',
      fileUrlList: [],
      isAIContents: result.nttAiPrcuseAt === 'Y',
    }

    init.value = JSON.stringify({
      ...state.value,
      imgOrVideoFile: saveImgOrVideoFile.value,
    })
    saveImgOrVideoFile.value = result.cardVideoJson
      ? JSON.parse(result.cardVideoJson)
      : null
    // postCreateAvailable.value = await postStore.fetchPostCreateAvailable({
    //   cmtyNttCtgrySn: result.cmtyNttCtgrySn,
    // })
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '임시저장 된 내용 선택',
      eventLabel: '',
      eventSlot: 'Modal',
      eventI18nAddr: '',
      eventComponent: 'Button',
      cmtyNttCtgrySn: result.cmtyNttCtgrySn,
      title: result.nttSj,
      tempSave: result.tmprNttSn,
      category: result.cmtyNttCtgrySn,
    })
  } catch (err) {
    console.log('error')
  }
}

// methods: 카드형 이미지 모델 변경
const handleOnChangeImages = (images: FileImage[]) => {
  saveImgOrVideoFile.value!.images = images
}

// methods: 비디오 모델 변경
const handleOnChangeVideo = (videos: FileVideo[]) => {
  saveImgOrVideoFile.value!.video = videos
}

// methods: 미리보기
const handleOnPreview = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const contents = await useReplacerPostContents(
      state.value.bodyData,
      PostContentsType.DETAIL,
      true
    )
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '미리보기 버튼 클릭',
      eventLabel: t('postCreate.button.preview'),
      eventSlot: 'Modal',
      eventI18nAddr: useKoreanTranslation('postCreate.button.preview'),
      eventComponent: 'Button',
    })
    await modalShow({
      component: NovaModalCommon,
      bind: {
        name: modalsName.MODAL_POST_PREVIEW,
        showCloseBtn: true,
        maxHeight: 500,
      },
      on: {
        close: () => {
          gtEvent('clickEvent', {
            eventCategory: '클릭',
            eventAction: '미리보기 모달 > 닫기 버튼',
            eventLabel: t('postCreate.modal.previewTitle'),
            eventSlot: 'Modal',
            eventI18nAddr: useKoreanTranslation(
              'noticeCreate.modal.previewTitle'
            ),
            eventComponent: 'Button',
          })
          modalHide(modalsName.MODAL_POST_PREVIEW)
        },
      },
      slots: {
        title: t('postCreate.modal.previewTitle'),
        contents: {
          component: NovaModalPostCreatePreview,
          bind: {
            title: state.value.title,
            isLivePost: props.source?.nttSndbrdLiveAt === 'Y',
            contents,
            hashtags: state.value.tags?.map((item, index) => ({
              hashtagNm: item,
              hashtagSn: index,
            })),
            imgOrVideoFile: saveImgOrVideoFile.value,
            createPostType: createPostType.value,
          },
          on: {
            close() {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '미리보기 모달 > 확인 버튼',
                eventLabel: t('postCreate.button.confirm'),
                eventSlot: 'Modal',
                eventI18nAddr: useKoreanTranslation(
                  'postCreate.button.confirm'
                ),
                eventComponent: 'Button',
              })
              modalHide(modalsName.MODAL_POST_PREVIEW)
            },
          },
        },
      },
    })
  } catch (err) {
    useToast(t('postCreate.toastMessage.errors.default'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

// methods: 포스트 발행
const handleOnSave = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `포스트 ${
      isEditMode.value ? '수정' : '작성'
    } > 게시 버튼 클릭`,
    eventLabel: t('postCreate.button.save'),
    eventSlot: 'Modal',
    eventI18nAddr: useKoreanTranslation('postCreate.button.save'),
    eventComponent: 'Button',
    cmtyNttCtgrySn: state.value.category,
    nttOthbcScopeCode: state.value.scope,
    nttSj: state.value.title,
    nttCn: state.value.bodyData,
    nttTyCode: createPostType.value,
    imgOrVideoFile: null,
    prmbrshCntntsAt: createPremiumPost.value ? 'Y' : 'N',
  })
  const valid = onValidate()

  if (!valid) return

  isEditMode.value ? await editPost() : await savePost()
}

// methods: 포스트 발행 전 유효성 검사
const onValidate = () => {
  if (
    !props.createPremiumPost &&
    state.value.category < 0 &&
    !isEditMode.value
  ) {
    useToast(t('postCreate.toastMessage.validateCategory'))
    return false
  } else if (state.value.title.length < 2) {
    useToast(t('postCreate.toastMessage.validateTitle'))
    return false
  } else if (state.value.bodyData.replace(/<[^>]+>/g, '').length < 15) {
    useToast(t('postCreate.toastMessage.validateContents'))
    return false
  } else {
    return true
  }
}

// methods: 포스트 배포(save)
const savePost = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })

    // 웹에디터 내 이미지 src 변경
    const { updatedHtml, replaceImgs } =
      await useReplacePostContentImgsToTempImgs(state.value.bodyData)

    const payload: CreatePostPayload = {
      cmtyNttCtgrySn: state.value.category,
      nttOthbcScopeCode: state.value.scope,
      nttSj: state.value.title,
      nttCn: updatedHtml,
      nttTyCode: createPostType.value,
      imgOrVideoFile: null,
      prmbrshCntntsAt: createPremiumPost.value ? 'Y' : 'N',
      tempImgUrlList: replaceImgs
        .filter((img) => img.tempSrc)
        .map((img) => img.tempSrc),
      nttAiPrcuseAt: state.value.isAIContents ? 'Y' : 'N',
    }

    // 프리미엄 포스트 일때
    if (createPremiumPost.value) {
      delete payload.cmtyNttCtgrySn
    }

    // 프리미엄 포스트 생성일 경우 블러 이미지 생성
    if (createPremiumPost.value) {
      const file = await useSnapshot(
        {
          videoCon: await postVideoRef.value?.getHtmlStringForSnapshot(),
          cardsCon: await postCardsRef.value?.getHtmlStringForSnapshot(),
          editorCon: updatedHtml,
        },
        {
          width: 930,
        },
        'blur(20px)'
      )
      const formData = new FormData()
      formData.append('imgFile', file)
      payload.blurTempImgUrl = await postStore.uploadTempImg(formData)
    }

    // 카드형 포스트 일때
    if (
      createPostType.value === 'CARD' &&
      saveImgOrVideoFile.value?.images?.length
    ) {
      payload.imgOrVideoFile = {
        fileId: saveImgOrVideoFile.value!.fileId,
        images: saveImgOrVideoFile.value!.images!,
        video: null,
      }
    }

    // 비디오형 포스트 일대
    if (
      createPostType.value === 'CVOD' &&
      saveImgOrVideoFile.value?.video?.length
    ) {
      payload.imgOrVideoFile = {
        fileId: saveImgOrVideoFile.value!.fileId,
        images: null,
        video: saveImgOrVideoFile.value!.video[0],
      }
    }

    // 등록된 해스 태그가 있을때
    if (state.value.tags?.length) {
      payload.hashTagList = state.value.tags
    }

    payload.nttCn = await useReplacerPostContents(
      updatedHtml,
      PostContentsType.DETAIL,
      true
    )
    await postStore.createPost(payload)
    gtEvent('postAction', {
      eventCategory: '포스트',
      eventAction: '포스트 게시',
      eventLabel: t('postCreate.button.save'),
      eventSlot: 'Modal',
      eventI18nAddr: useKoreanTranslation('postCreate.button.save'),
      eventComponent: 'Button',
      cmtyNttCtgrySn: state.value.category,
      nttOthbcScopeCode: state.value.scope,
      nttSj: state.value.title,
      nttCn: state.value.bodyData,
      nttTyCode: createPostType.value,
      imgOrVideoFile: null,
      prmbrshCntntsAt: createPremiumPost.value ? 'Y' : 'N',
    })
    emit('close')
    useToast(
      t('postCreate.toastMessage.createPostSuccess', {
        postTitle: payload.nttSj,
      })
    )
  } catch (err) {
    const defaultMessage = t('postCreate.toastMessage.errors.default')

    if (err instanceof AxiosError) {
      const status = String(err.response?.status)
      const errorMessage = Object.keys(
        (messages.value[locale.value].postCreate as any).toastMessage.errors
      ).find((key) => key === status)
      useToast(
        errorMessage
          ? t(`postCreate.toastMessage.errors.${errorMessage}`)
          : defaultMessage
      )
    } else {
      useToast(defaultMessage)
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

// methods: 포스트 수정(edit)
const editPost = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })

    // 웹에디터 내 이미지 src 변경
    const { updatedHtml } = await useReplacePostContentImgsToTempImgs(
      state.value.bodyData
    )

    const payload: EditPostPayload = {
      cmtyNttCtgrySn: state.value.category,
      cmtyNttSn: props.source!.cmtyNttSn,
      fileUrlList: getImagesFromContents(updatedHtml, true),
      imgOrVideoFile: saveImgOrVideoFile.value,
      nttCn: updatedHtml,
      nttOthbcScopeCode: state.value.scope,
      nttSj: state.value.title,
      nttTyCode: props.source!.nttTyCode,
      prmbrshCntntsAt: props.source!.prmbrshCntntsAt,
      nttAiPrcuseAt: state.value.isAIContents ? 'Y' : 'N',
    }

    // 프리미엄 포스트 일때
    if (createPremiumPost.value) {
      delete payload.cmtyNttCtgrySn
    }

    // 프리미엄 포스트 생성일 경우 블러 이미지 생성
    if (createPremiumPost.value) {
      const file = await useSnapshot(
        {
          videoCon: await postVideoRef.value?.getHtmlStringForSnapshot(),
          cardsCon: await postCardsRef.value?.getHtmlStringForSnapshot(),
          editorCon: updatedHtml,
        },
        {
          width: 930,
        },
        'blur(20px)'
      )
      const formData = new FormData()
      formData.append('imgFile', file)
      payload.blurTempImgUrl = await postStore.uploadTempImg(formData)
    }

    // 등록된 해쉬 태그가 있을때
    if (state.value.tags?.length) {
      payload.hashTagList = state.value.tags
    }

    payload.nttCn = await useReplacerPostContents(
      updatedHtml,
      PostContentsType.DETAIL,
      true
    )

    await postStore.editPost(payload)
    gtEvent('postAction', {
      eventCategory: '포스트',
      eventAction: '포스트 수정',
      eventLabel: t('postCreate.button.save'),
      eventSlot: 'Modal',
      eventI18nAddr: useKoreanTranslation('postCreate.button.save'),
      eventComponent: 'Button',
      cmtyNttCtgrySn: state.value.category,
      nttOthbcScopeCode: state.value.scope,
      nttSj: state.value.title,
      nttCn: state.value.bodyData,
      nttTyCode: createPostType.value,
      imgOrVideoFile: null,
      prmbrshCntntsAt: createPremiumPost.value ? 'Y' : 'N',
    })
    emit('close')
    useToast(
      t('postCreate.toastMessage.editPostSuccess', {
        postTitle: payload.nttSj,
      })
    )
  } catch (err) {
    useToast(t('postCreate.toastMessage.errors.default'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

// eventBus: 팝업창 끄기
useListen('post:createClose', async () => {
  const isChanged =
    init.value !==
    JSON.stringify({
      ...state.value,
      imgOrVideoFile: saveImgOrVideoFile.value,
    })

  if (isChanged) {
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('cancel'),
            theme: 'transparent',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction:
                  '포스트 작성 모달 닫기 > 작성중인 내용 삭제 확인 > 취소',
                eventLabel: t('cancel'),
                eventSlot: '모달 닫기',
                eventI18nAddr: useKoreanTranslation('createPost'),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
          {
            label: t('confirm'),
            theme: 'primary-blue-dark',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction:
                  '포스트 작성 모달 닫기 > 작성중인 내용 삭제 확인 > 확인',
                eventLabel: t('confirm'),
                eventSlot: '포스트 작성 모달 닫기 > 닫기 확인',
                eventI18nAddr: useKoreanTranslation('confirm'),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
              await modalHide(modalsName.MODAL_CREATE_POST)
            },
          },
        ],
      },
      slots: {
        title: t('postCreate.modal.dialogTitle'),
        content: t('postCreate.modal.dialogContent'),
      },
    })
  } else {
    await modalHide(modalsName.MODAL_DIALOG)
    await modalHide(modalsName.MODAL_CREATE_POST)
  }
})

// methods: 미리보기, 저장 버튼 비활성화
const setPlayBtnActive = () => {
  isDisabled.value = false
}

// methods: 에디터에서 이미지 목록 추출(prev 상태의 이미지 목록과 비교하여 삭제 여부 및 임시 여부 확인)
const getImagesFromContents = (contents: string, submit = false) => {
  // 1. 콘텐츠에서 이미지 추출(외부 이미지 제외)
  const container = document.createElement('div')
  container.innerHTML = contents
  const imageEls = container.querySelectorAll('img')
  const nextImages: EditorImage[] = Array.prototype.slice
    .call(imageEls)
    .filter((img) => {
      const fileUrl = img.getAttribute('src')
      return (
        fileUrl.includes(config.public.TEMP_IMAGE_URL) ||
        fileUrl.includes(config.public.POST_RELEASED_IMAGE_URL)
      )
    })
    .map((img) => {
      const fileUrl = img.getAttribute('src')

      return {
        deleteAt:
          !fileUrl.includes(config.public.TEMP_IMAGE_URL) &&
          state.value.fileUrlList.findIndex(
            (item) => item.fileUrl === fileUrl
          ) === -1
            ? 'Y'
            : 'N',
        fileUrl,
        tempAt: fileUrl.includes(config.public.TEMP_IMAGE_URL) ? 'Y' : 'N',
      }
    })

  // 2. 수정 전 이미지와 비교하여 삭제된 이미지 추출
  let deletedImages: EditorImage[] = []
  deletedImages = state.value.fileUrlList.filter(
    (prevImg) =>
      nextImages.findIndex((nextImg) => nextImg.fileUrl === prevImg.fileUrl) ===
      -1
  )

  // 3. 수정 하기 위한 페이로드 생성시 변경 사항 없는 이미지는 제외
  if (submit) {
    return [...nextImages, ...deletedImages].filter(
      (img) => !(img.deleteAt === 'N' && img.tempAt === 'N')
    )
  }

  return [...nextImages, ...deletedImages]
}

// methods: 에디터에서 로딩 인디케이터 노출
const handleOnTipTapLoading = (loading: boolean) => {
  editorLoading.value = loading
}

const handleScroll = () => {
  if (appStore.isApp && window.scrollY === 0) {
    window.scrollTo({ top: 1 })
  }
}

const handleOnChangeAIContents = (value: boolean) => {
  state.value.isAIContents = value
}

watch(
  () => state.value.category,
  (cur) => {
    if (cur !== CategorySN.CREATOR_CONTENTS) {
      state.value.isAIContents = false
    }
  }
)

onMounted(() => {
  if (appStore.isApp && window.scrollY === 0) {
    window.scrollTo({ top: 1 })
    window.addEventListener('scroll', handleScroll)
  }
  setInit()
})

onBeforeUnmount(() => {
  if (appStore.isApp) {
    window.removeEventListener('scroll', handleScroll)
  }
})
</script>

<template>
  <div class="create-content-wrap">
    <NovaLayoutScrollContainer class="create-content">
      <div class="content-top">
        <span class="profile">
          <NovaUserCell
            :portrait="userStore.user?.userProflUrl"
            :user-name="userStore.user?.userNcnm"
            :user-sn="userStore.user?.userSn"
            :creator-link="userStore.user?.creatorLink"
          />
        </span>

        <div v-if="isCreatorContents" class="extra">
          <div class="ai-content-switch">
            <Tippy :interactive="true" :theme="'gray'" :placement="'top'">
              <span class="tooltip-label" />

              <template #content>
                <p v-dompurify-html="t('aiContents.regGuide')" />
              </template>
            </Tippy>

            <span class="label">{{ t('aiContents.isAIContents') }}</span>

            <NovaSwitch
              :model-value="!!state.isAIContents"
              :theme="'IOS'"
              @update="handleOnChangeAIContents"
            />
          </div>
        </div>

        <!--        <div class="extra">-->
        <!--          <div v-if="isPublishLimitCategory" class="publish-limit">-->
        <!--            <Tippy :interactive="true" :theme="'gray'" :placement="'top-end'">-->
        <!--              <span class="tooltip-label" />-->
        <!--              <template #content>-->
        <!--                <p v-dompurify-html="$t('postCreate.createAvailableCount')" />-->
        <!--              </template>-->
        <!--            </Tippy>-->
        <!--            <span class="count">-->
        <!--              {{ postCreateAvailable?.publishableCount || 0 }}/{{-->
        <!--                postCreateAvailable?.svcActCrtrCtgryPostWklyMaxCo || 0-->
        <!--              }}-->
        <!--            </span>-->
        <!--          </div>-->
        <!--          &lt;!&ndash;        <div v-if="isOpenStatus" class="scope">-->
        <!--            <NovaDropdown-->
        <!--              :placeholder="$t('postCreate.placeholder.public')"-->
        <!--              :menus="postScopes"-->
        <!--              :active-value="state.scope"-->
        <!--              theme="dark"-->
        <!--              @update="handleOnUpdateScope"-->
        <!--            />-->
        <!--          </div>&ndash;&gt;-->
        <!--        </div>-->
      </div>

      <div
        v-if="!isEditMode"
        :class="[
          'select-box grid-wrapper gap-12',
          `grid-${createPremiumPost ? 1 : 2}`,
        ]"
      >
        <NovaDropdown
          :placeholder="t('postCreate.placeholder.tempSave')"
          :menus="[]"
          :active-value="state.category"
          @click="openTempList"
        />

        <NovaDropdown
          v-if="!createPremiumPost"
          :placeholder="t('postCreate.placeholder.category')"
          :menus="categories"
          :active-value="state.category"
          :is-loading="isLoadingCategoryChange"
          @update="(value) => {
            handleOnUpdateCategory(value as number)
          }"
        />
      </div>

      <div class="content-title">
        <NovaInput
          v-model="state.title"
          class="input-title"
          :disabled="!isPostCreateAvailable"
          :max-byte="200"
          :placeholder="t('postCreate.placeholder.title')"
        />
      </div>

      <div v-if="createPostType !== 'NORM'" class="pre-post-contents">
        <NovaBoxPostContentsSnapshotImage
          v-if="createPostType === 'CARD'"
          ref="postCardsRef"
          :images="saveImgOrVideoFile?.images"
          :edit-mode="true"
          :disabled="!isPostCreateAvailable"
          @change:images="handleOnChangeImages"
        />

        <NovaBoxPostContentsSnapshotVideo
          v-if="createPostType === 'CVOD' && saveImgOrVideoFile?.video?.length"
          :videos="saveImgOrVideoFile?.video || []"
          :create-mode="true"
          :edit-mode="source?.nttSndbrdLiveAt === 'N'"
          :disabled="!isPostCreateAvailable"
          @play-btn-active="setPlayBtnActive"
          @change:video="handleOnChangeVideo"
        />
      </div>

      <div class="editor-section">
        <NovaTipTap
          v-model="state.bodyData"
          :placeholder="t('postCreate.placeholder.content')"
          :disabled="!isPostCreateAvailable"
          :is-loading="editorLoading"
          @on-loading="handleOnTipTapLoading"
        >
          <template #custom-placeholder>
            <NovaModalPostCreateEditPlaceholder
              :post-category-type="editorPlaceholder"
            />
          </template>
        </NovaTipTap>
      </div>

      <div v-if="!createPremiumPost" class="hashtag-wrap">
        <NovaCreateHashtag
          :hashtag-set="hashtags"
          :tags="state.tags"
          @update-tags="updateTags"
        />
      </div>
    </NovaLayoutScrollContainer>

    <div class="content-action">
      <NovaButtonText
        :label="t('postCreate.button.tempSave')"
        :theme="'secondary-gray'"
        :size="32"
        :full-width="true"
        class="btn-action"
        @click="handleOnTempSave"
      />

      <NovaButtonText
        :label="t('postCreate.button.preview')"
        :theme="'secondary-gray'"
        :size="32"
        :full-width="true"
        class="btn-action"
        :disabled="isDisabled"
        @click="handleOnPreview"
      />

      <NovaButtonText
        :label="t('postCreate.button.save')"
        :theme="'primary-blue-dark'"
        :size="32"
        :full-width="true"
        :disabled="isDisabled"
        class="btn-action"
        @click="handleOnSave"
      />
    </div>
  </div>
</template>

<style module>
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev,
.swiper-button-next {
  width: 24px !important;
  height: 24px !important;
  margin-top: 0 !important;
  transform: translateY(-50%) !important;
  background-size: 100% 100%;
  background-color: transparent;
  background-image: url('assets/images/icon-swiper-post.png') !important;
}
.swiper-button-next {
  transform: translateY(-50%) rotate(180deg) !important;
}
</style>

<style lang="scss" scoped>
.create-content-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
}

.create-content {
  padding: 0 20px;
}

.content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .profile {
    display: flex;
    align-items: center;

    .text {
      margin-left: 10px;
      @include text-style($text-body-14-bold);
    }
  }

  .extra {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: 6px;

    .post-create-available-count {
      @include text-style($text-body-14-medium);
      color: $color-text-3;
    }

    .scope {
    }
  }

  .ai-content-switch {
    display: flex;
    align-items: center;
    .label {
      color: #97989d;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.7px;
      margin-left: 3px;
      margin-right: 6px;
    }
  }
}

.select-box {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.content-title,
.pre-post-contents,
.editor-section,
.hashtag-wrap {
  margin-top: 12px;
}

.pre-post-contents {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.editor-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid $color-bg-custom-2;
  border-radius: 8px;
}

.content-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 20px 20px 51px;

  > .btn-action {
    width: 100px;
  }
}

@include mobile {
  .create-content-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    position: relative;
  }

  .create-content {
    flex-grow: 1;
  }
  .content-action {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-bottom: 24px;

    :deep(.btn) {
      width: 100%;
    }
  }
}

.tooltip-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-bg-custom-11;
  &:after {
    content: '?';
    color: $color-bg-3;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
  }
}

.publish-limit {
  display: flex;
  align-items: center;
  gap: 5px;

  .count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    border-radius: 4px;
    color: $color-text-3;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    background-color: $color-bg-2;
  }
}
</style>
