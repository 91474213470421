export default {
  board: () => import("/codebuild/output/src3818930399/src/layouts/board.vue"),
  "call-back": () => import("/codebuild/output/src3818930399/src/layouts/call-back.vue"),
  "creator-home": () => import("/codebuild/output/src3818930399/src/layouts/creator-home.vue"),
  default: () => import("/codebuild/output/src3818930399/src/layouts/default.vue"),
  fund: () => import("/codebuild/output/src3818930399/src/layouts/fund.vue"),
  hashtag: () => import("/codebuild/output/src3818930399/src/layouts/hashtag.vue"),
  "nova-plus": () => import("/codebuild/output/src3818930399/src/layouts/nova-plus.vue"),
  "one-column-full-height": () => import("/codebuild/output/src3818930399/src/layouts/one-column-full-height.vue"),
  "one-column": () => import("/codebuild/output/src3818930399/src/layouts/one-column.vue"),
  search: () => import("/codebuild/output/src3818930399/src/layouts/search.vue"),
  "user-home": () => import("/codebuild/output/src3818930399/src/layouts/user-home.vue")
}