<script setup lang="ts">
import { AxiosError } from 'axios'
import type {
  ReqRecommendAndUnRecommendParam,
  NovaBoxPostActionRecommendProps,
} from './NovaBoxPostActionRecommend.types'
import type { RecommendUser, RecommendType } from '@store'

const props = withDefaults(defineProps<NovaBoxPostActionRecommendProps>(), {
  actionAbleRecommend: true,
})
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { userStore, showSignInDialog } = useMembershipProcess()
const postStore = usePostStore()
const loading = ref(false)
const recommend = computed(() => props.rcFlag === '1')
const unRecommend = computed(() => props.dvFlag === '1')
const { generateReCaptchaToken } = useReCaptcha()
/**
 * 좋아요, 좋아요 취소, 싫어요, 싫어요 취소 API 호출 게이트
 * action               recommend(rcFlag)      unRecommend(dvFlag)    api                   success status
 * (1-1)recommend       false(0)               false(0)               좋아요                   { rcFlag: '1', dvFlag: '0' }
 * (1-2)recommend       false(0)               true(1)                싫어요 취소 > 좋아요        { rcFlag: '1', dvFlag: '0' }
 * (1-3)recommend       true(1)                false(0)               좋아요 취소               { rcFlag: '0', dvFlag: '0' }
 * (2-1)unRecommend     false(0)               false(0)               싫어요                  { rcFlag: '0', dvFlag: '1' }
 * (2-2)unRecommend     false(0)               true(1)                싫어요 취소              { rcFlag: '0', dvFlag: '0' }
 * (2-3)unRecommend     true(1)                false(0)               좋아요 취소 > 싫어요        { rcFlag: '0', dvFlag: '1' }
 * @param type
 */
const handleOnActionGate = async (type: RecommendType) => {
  const recommendTargetType =
    props.recommendTargetType === 'post' ? '포스트' : '댓글'
  const like = !recommend.value && !unRecommend.value ? '좋아요' : '좋아요 취소'
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${recommendTargetType} > ${like}`,
    eventLabel: !recommend.value && !unRecommend.value ? 'like' : 'like-fill',
    eventSlot: `${recommendTargetType} > ${like}`,
    eventI18nAddr: '',
    eventComponent: 'Button',
    recommendTarget: props.recommendTarget,
    recommendTargetType: props.recommendTargetType,
    recommendCount: props.recommendCount,
  })
  // 포스트에 대한 좋아요, 좋아요 취소는 1-1, 1-3 두개만 작동 싫어요 뭔지 모르겠음
  // 프리미엄 구독 여부에 따라 액션 블럭
  if (!props.actionAbleRecommend) {
    return
  }

  // 로딩중 중복 이벤트 발생 막기
  if (loading.value) {
    return
  }

  // 비로그인 상황에서는 로그인 가능하도록 팝업 열기
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  // props.recommend: true && props.unRecommend: true(나올 수 없는 상태)
  if (recommend.value && unRecommend.value) {
    throw new Error('recommend action status rcFlag: true, dvFlag: true')
  }

  // 1-1.
  if (type === 'recommend' && !recommend.value && !unRecommend.value) {
    switch (props.recommendTargetType) {
      case 'post': {
        const recaptchaToken = await generateReCaptchaToken('RECOMMEND')
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqPostRecommend(
              { cmtyNttSn: props.recommendTarget, recaptchaToken },
              {
                rcFlag: '1',
                dvFlag: '0',
                rcCount: props.recommendCount + 1,
                recommendUserList: getUpdateRecommendUsers('recommend'),
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.recommend.error'),
            success: t(
              'statusMessages.recommendAndUnRecommend.recommend.success'
            ),
          },
        })
        break
      }
      case 'comment':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            postStore.reqCommentRecommend(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '1',
                dvFlag: '0',
                rcCount: props.recommendCount + 1,
                recommendUserList: getUpdateRecommendUsers('recommend'),
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.recommend.error'),
            success: t(
              'statusMessages.recommendAndUnRecommend.recommend.success'
            ),
          },
        })
        break
      default:
        throw new Error(
          `recommend target ${props.recommendTargetType} is not match`
        )
    }
    gtEvent('replyAction', {
      eventCategory: '댓글',
      eventAction: `${recommendTargetType} > ${like}`,
      eventLabel: !recommend.value && !unRecommend.value ? 'like' : 'like-fill',
      eventSlot: `${recommendTargetType} > ${like}`,
      eventI18nAddr: '',
      eventComponent: 'Button',
      recommendTarget: props.recommendTarget,
      recommendTargetType: props.recommendTargetType,
      recommendCount: props.recommendCount,
    })
    return
  }

  // 1-2.
  if (type === 'recommend' && !recommend.value && unRecommend.value) {
    switch (props.recommendTargetType) {
      case 'post': {
        const recaptchaToken = await generateReCaptchaToken('RECOMMEND')
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqPostUnRecommendCancel(
              { cmtyNttSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount + 0,
              }
            )
            await postStore.reqPostRecommend(
              { cmtyNttSn: props.recommendTarget, recaptchaToken },
              {
                rcFlag: '1',
                dvFlag: '0',
                rcCount: props.recommendCount + 1,
                recommendUserList: getUpdateRecommendUsers('recommend'),
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.recommend.error'),
          },
        })
        break
      }
      case 'comment':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqCommentUnRecommendCancel(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount + 0,
              }
            )
            await postStore.reqCommentRecommend(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '1',
                dvFlag: '0',
                rcCount: props.recommendCount + 1,
                recommendUserList: getUpdateRecommendUsers('recommend'),
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.recommend.error'),
          },
        })
        break
      default:
        throw new Error(
          `recommend target ${props.recommendTargetType} is not match`
        )
    }
    return
  }

  // 1-3.
  if (type === 'recommend' && recommend.value && !unRecommend.value) {
    switch (props.recommendTargetType) {
      case 'post':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqPostRecommendCancel(
              { cmtyNttSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount - 1,
                recommendUserList: getUpdateRecommendUsers('recommendCancel'),
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.recommendCancel'),
          },
        })
        break
      case 'comment':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqCommentRecommendCancel(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount - 1,
                recommendUserList: getUpdateRecommendUsers('recommendCancel'),
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.recommendCancel'),
          },
        })
        break
      default:
        throw new Error(
          `recommend target ${props.recommendTargetType} is not match`
        )
    }
    gtEvent('replyAction', {
      eventCategory: '댓글',
      eventAction: `${recommendTargetType} > ${like}`,
      eventLabel: !recommend.value && !unRecommend.value ? 'like' : 'like-fill',
      eventSlot: `${recommendTargetType} > ${like}`,
      eventI18nAddr: '',
      eventComponent: 'Button',
      recommendTarget: props.recommendTarget,
      recommendTargetType: props.recommendTargetType,
      recommendCount: props.recommendCount,
    })
    return
  }

  // 2-1.
  if (type === 'unRecommend' && !recommend.value && !unRecommend.value) {
    switch (props.recommendTargetType) {
      case 'post':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqPostUnRecommend(
              { cmtyNttSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '1',
                rcCount: props.recommendCount + 0,
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.unRecommend'),
          },
        })
        break
      case 'comment':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqCommentUnRecommend(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '1',
                rcCount: props.recommendCount + 0,
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.unRecommend'),
          },
        })
        break
      default:
        throw new Error(
          `recommend target ${props.recommendTargetType} is not match`
        )
    }
    return
  }

  // 2-2.
  if (type === 'unRecommend' && !recommend.value && unRecommend.value) {
    switch (props.recommendTargetType) {
      case 'post':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqPostUnRecommendCancel(
              { cmtyNttSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount + 0,
              }
            )
          },
          message: {
            error: t(
              'statusMessages.recommendAndUnRecommend.unRecommendCancel'
            ),
          },
        })
        break
      case 'comment':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqCommentUnRecommendCancel(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount + 0,
              }
            )
          },
          message: {
            error: t(
              'statusMessages.recommendAndUnRecommend.unRecommendCancel'
            ),
          },
        })
        break
      default:
        throw new Error(
          `recommend target ${props.recommendTargetType} is not match`
        )
    }
    return
  }

  // 2-3.
  if (type === 'unRecommend' && recommend.value && !unRecommend.value) {
    switch (props.recommendTargetType) {
      case 'post':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqPostRecommendCancel(
              { cmtyNttSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount - 1,
                recommendUserList: getUpdateRecommendUsers('recommendCancel'),
              }
            )
            await postStore.reqPostUnRecommend(
              { cmtyNttSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '1',
                rcCount: props.recommendCount + 0,
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.unRecommend'),
          },
        })
        break
      case 'comment':
        await reqRecommendAndUnRecommend({
          request: async () => {
            await useSleeper()
            await postStore.reqCommentRecommendCancel(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '0',
                rcCount: props.recommendCount - 1,
                recommendUserList: getUpdateRecommendUsers('recommendCancel'),
              }
            )
            await postStore.reqCommentUnRecommend(
              { cmtyNttAnswerSn: props.recommendTarget },
              {
                rcFlag: '0',
                dvFlag: '1',
                rcCount: props.recommendCount + 0,
              }
            )
          },
          message: {
            error: t('statusMessages.recommendAndUnRecommend.unRecommend'),
          },
        })
        break
      default:
        throw new Error(
          `recommend target ${props.recommendTargetType} is not match`
        )
    }
  }
}

// 업데이트할 좋아요 한 사용자 목록 추출
const getUpdateRecommendUsers = (
  type: 'recommend' | 'recommendCancel'
): RecommendUser[] => {
  const userSn = userStore.user!.userSn
  const replaceRecommendUsers = [...props.recommendUserList]
  const recommendUserIdx = replaceRecommendUsers.findIndex(
    (user) => user.userSn === userSn
  )

  // 1. 좋아요
  if (type === 'recommend') {
    const updateUser: RecommendUser = {
      userSn: userStore.user!.userSn,
      userNcnm: userStore.user!.userNcnm,
      profileImgUrl: userStore.user!.userProflUrl,
    }

    // 1-1. 좋아요 한 사용자 리스트에 사용자가 없을때
    if (recommendUserIdx === -1) {
      return [updateUser].concat(replaceRecommendUsers)
      // replaceRecommendUsers.push(updateUser)
    }

    // 1-2. 좋아요 한 사용자 리스트에 사용자가 있을때
    else {
      replaceRecommendUsers.splice(recommendUserIdx, 1)
      return [updateUser].concat(replaceRecommendUsers)
    }
  }

  // 2. 좋아요 취소
  replaceRecommendUsers.splice(recommendUserIdx, 1)
  return replaceRecommendUsers
}

// 좋아요, 싫어요 액션 호출
const reqRecommendAndUnRecommend = async (
  param: ReqRecommendAndUnRecommendParam
) => {
  try {
    loading.value = true
    await param.request()
    if (param.message.success) useToast(param.message.success)
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case 2037:
          useToast(t('statusMessages.recommendAndUnRecommend.recommend.2037'))
          break
        case 8048:
          useToast(t('statusMessages.recommendAndUnRecommend.recommend.8048'))
          break
        default:
          useToast(param.message.error)
          break
      }
    } else {
      useToast(param.message.error)
    }
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <NovaBoxPostAction
    :icon="{
      type: 'outline',
      name: recommend ? 'like-fill' : 'like',
    }"
    :display-number="recommendCount"
    :action-type="'recommend'"
    :loading="loading"
    :disabled="viewType !== 'detail'"
    @click.stop="handleOnActionGate('recommend')"
  />
</template>
