import routerOptions0 from "/codebuild/output/src3818930399/src/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/codebuild/output/src3818930399/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}