<script setup lang="ts">
import { LayerType } from '@store'
import { ROUTES, FAMILY_SITES, SOCIALS } from '@configs'
import { NovaModalCommon, NovaModalReportCustomer } from '#components'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { show: modalShow, hide: modalHide } = useModal()
const { showSignInDialog } = useMembershipProcess()
const config = useRuntimeConfig()
const layoutStore = useLayoutStore()
const etcStore = useEtcStore()
const userStore = useUserStore()
const mypageStore = useMyPageStore()

const applyCreator = computed(() => {
  const status = mypageStore.applyCreatorStatus
  switch (status) {
    // 심사중
    case 'underReview':
      return {
        badge: {
          label: t('applyCreator.guide.apply.gateStatus.underReview'),
          theme: 'secondary-green-light-80',
        },
        showLink: true,
      }
    // 승인거부
    case 'reject':
      return {
        badge: {
          label: t('applyCreator.guide.apply.gateStatus.confirm'),
          theme: 'secondary-gray',
        },
        showLink: true,
      }
    // 승인완료(크리에이터 전환된 상태)
    case 'approved':
      return {
        badge: undefined,
        showLink: false,
      }
    // 신청 가능
    case 'available':
    default:
      return {
        badge: undefined,
        showLink: true,
      }
  }
})

const handleOnGoApplyCreator = () => {
  if (
    mypageStore.applyCreatorStatus === 'reject' ||
    mypageStore.applyCreatorStatus === 'underReview' ||
    mypageStore.applyCreatorStatus === 'available'
  ) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '크리에이터 신청하기',
      eventLabel: t('applyCreator.entry'),
      eventSlot: '유저 패널',
      eventI18nAddr: useKoreanTranslation('applyCreator.entry'),
      eventComponent: 'Button',
      applyCreatorStatus: mypageStore.applyCreatorStatus,
    })
    useNavigations({ url: ROUTES.APPLY_CREATOR.path })
  }
}

// 메뉴 목록: 헬프센터
const helpCenters = computed(() => [
  {
    name: t('menus.serviceIntro'),
    action: () => {
      menusActionMap(() =>
        useNavigations({ external: true, url: config.public.NOVAGUIDE_URL })
      )
    },
  },
  {
    name: t('menus.userGuide'),
    action: () => {
      menusActionMap(() =>
        useNavigations({ external: true, url: config.public.REWARD_GUIDE_URL })
      )
    },
  },
  {
    name: t('menus.faq'),
    action: () => {
      menusActionMap(() => useNavigations({ url: ROUTES.BOARD_FAQ.path }))
    },
  },
  {
    name: t('menus.personalCS'),
    action: () => {
      menusActionMap(() => {
        if (!userStore.isSignIn) {
          showSignInDialog()
          return
        }

        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '고객 문의 모달 오픈',
          eventLabel: t('cs'),
          eventSlot: '유저 패널',
          eventI18nAddr: useKoreanTranslation('cs'),
          eventComponent: 'Button',
        })
        modalShow({
          component: NovaModalCommon,
          bind: {
            name: modalsName.MODAL_CS,
            showCloseBtn: true,
          },
          on: {
            close: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '고객 문의 모달 닫기',
                eventLabel: t('cs'),
                eventSlot: '유저 패널',
                eventI18nAddr: useKoreanTranslation('cs'),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_CS)
            },
          },
          slots: {
            title: t('qna.title'),
            contents: {
              component: NovaModalReportCustomer,
            },
          },
        })
      })
    },
  },
])

// 메뉴 목록: 이벤트
const events = computed(() => [
  {
    name: t('menus.eventList'),
    action: () => {
      menusActionMap(() => useNavigations({ url: ROUTES.BOARD_EVENT.path }))
    },
  },
  {
    name: t('menus.dailyCheck'),
    action: () => {
      menusActionMap(() => useNavigations({ url: ROUTES.DAILY_CHECK.path }))
    },
  },
  {
    name: t('menus.creatorFund'),
    action: () => {
      menusActionMap(() => useNavigations({ url: ROUTES.FUND.path }))
    },
  },
])

// 메뉴 목록: 정책
const policies = computed(() =>
  etcStore.terms.map((term) => ({
    name: term.stplatNm,
    action: () => {
      menusActionMap(() =>
        useNavigations({
          url: useRoutePathIdChange(ROUTES.TERMS.path, { id: term.stplatId }),
        })
      )
    },
  }))
)

// 메뉴 목록: 페밀리 사이트
const familySites = computed(() =>
  FAMILY_SITES.map((site) => ({
    name: t(`menus.${site.key}`),
    action: () => {
      menusActionMap(() => useNavigations({ external: true, url: site.url }))
    },
  }))
)

// 소셜
const socials = computed(() =>
  SOCIALS.map((item) => ({
    icon: item.icon,
    size: item.size,
    action: () => {
      menusActionMap(() => useNavigations({ external: true, url: item.url }))
    },
  }))
)

// 카피라이트
const copyRight = computed(() =>
  t('corporationInfo.copyright', { year: new Date().getFullYear() })
)

const menusActionMap = (action: () => any) => {
  action()
  layoutStore['layer:close'](LayerType.MENU_PANEL)
}

onUnmounted(() => {
  layoutStore['layer:close'](LayerType.MENU_PANEL)
})
</script>

<template>
  <div class="menus-wrap">
    <div class="menus-container">
      <!-- 메뉴: 지갑 -->
      <NovaHeaderMenuGroup
        :group-name="t('menus.wallet')"
        :group-link="
          () => {
            menusActionMap(() =>
              useNavigations({ external: true, url: config.public.REWARD_URL })
            )
          }
        "
      />

      <!-- 메뉴: 크리에이터 신청하기 -->
      <ClientOnly>
        <NovaHeaderMenuGroup
          v-if="userStore.user?.cmtyUserSeCode === 'G'"
          :group-name="t('applyCreator.entry')"
          :group-link="
            applyCreator.showLink
              ? () => {
                  menusActionMap(() => handleOnGoApplyCreator())
                }
              : undefined
          "
        >
          <template #extra>
            <NovaButtonText
              v-if="applyCreator.badge"
              :label="applyCreator.badge.label"
              :theme="applyCreator.badge.theme"
              :size="20"
              :readonly="true"
            />
          </template>
        </NovaHeaderMenuGroup>
      </ClientOnly>

      <!-- 메뉴: 공지사항 -->
      <NovaHeaderMenuGroup
        :group-name="t('menus.notice')"
        :group-link="
          () => {
            menusActionMap(() =>
              useNavigations({ url: ROUTES.BOARD_NOTICE.path })
            )
          }
        "
      />

      <!-- 메뉴: 헬프 센터 -->
      <NovaHeaderMenuGroup :group-name="t('menus.helpCenter')">
        <template #menus>
          <NovaHeaderMenuItem
            v-for="(item, index) in helpCenters"
            :key="index"
            :label="item.name"
            @click="item.action"
          />
        </template>
      </NovaHeaderMenuGroup>

      <!-- 메뉴: 이벤트 -->
      <NovaHeaderMenuGroup :group-name="t('menus.event')">
        <template #menus>
          <NovaHeaderMenuItem
            v-for="(item, index) in events"
            :key="index"
            :label="item.name"
            @click="item.action"
          />
        </template>
      </NovaHeaderMenuGroup>

      <!-- 메뉴: 정책 -->
      <NovaHeaderMenuGroup
        v-if="policies.length"
        :group-name="t('menus.policy')"
      >
        <template #menus>
          <NovaHeaderMenuItem
            v-for="(item, index) in policies"
            :key="index"
            :label="item.name"
            @click="item.action"
          />
        </template>
      </NovaHeaderMenuGroup>

      <!-- 메뉴: 패밀리 사이트 -->
      <NovaHeaderMenuGroup :group-name="t('menus.familySite')">
        <template #menus>
          <NovaHeaderMenuItem
            v-for="(item, index) in familySites"
            :key="index"
            :label="item.name"
            @click="item.action"
          />
        </template>
      </NovaHeaderMenuGroup>

      <!-- 소셜 -->
      <div class="socials">
        <button
          v-for="(item, index) in socials"
          :key="index"
          type="button"
          class="social"
          @click="item.action"
        >
          <NovaIcon :icon="item.icon" :size="item.size" />
        </button>
      </div>
    </div>

    <NovaDivider :gap="16" />

    <p class="copy-right">{{ copyRight }}</p>
  </div>
</template>

<style lang="scss" scoped>
.menus-wrap {
  padding: 20px;

  .menus-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .socials {
    display: flex;
    align-items: center;
    gap: 20px;

    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $color-bg-custom-11;

      :deep(i:before) {
        color: $color-white !important;
      }
    }
  }

  .copy-right {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.38px;
    text-align: left;
    color: $color-text-3;
  }
}
</style>
