import { default as applyjWBrZ9Fnq7Meta } from "/codebuild/output/src3818930399/src/pages/apply-creator/apply.vue?macro=true";
import { default as indexBGJLk2OvRcMeta } from "/codebuild/output/src3818930399/src/pages/apply-creator/index.vue?macro=true";
import { default as additionalD319DhyUHcMeta } from "/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/additional.vue?macro=true";
import { default as completeP0zGhBmsfkMeta } from "/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/complete.vue?macro=true";
import { default as indexfaIpwtNrPfMeta } from "/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/index.vue?macro=true";
import { default as indexxYU3aDU5S3Meta } from "/codebuild/output/src3818930399/src/pages/apply-rent/index.vue?macro=true";
import { default as form7kTG9FShSXMeta } from "/codebuild/output/src3818930399/src/pages/apply-super-moon/form.vue?macro=true";
import { default as index29oyyjOIIUMeta } from "/codebuild/output/src3818930399/src/pages/apply-super-moon/index.vue?macro=true";
import { default as _91id_93a4iRZ2aRqjMeta } from "/codebuild/output/src3818930399/src/pages/board/event/[id].vue?macro=true";
import { default as indexPwz4aQH54iMeta } from "/codebuild/output/src3818930399/src/pages/board/event/index.vue?macro=true";
import { default as _91id_93SzLF7hN3r1Meta } from "/codebuild/output/src3818930399/src/pages/board/faq/[id].vue?macro=true";
import { default as indexTXPJf4Kvv3Meta } from "/codebuild/output/src3818930399/src/pages/board/faq/index.vue?macro=true";
import { default as _91id_93pfH2VWjbrdMeta } from "/codebuild/output/src3818930399/src/pages/board/news/[id].vue?macro=true";
import { default as indexGOUezlqIjUMeta } from "/codebuild/output/src3818930399/src/pages/board/news/index.vue?macro=true";
import { default as _91id_93rPkMlxVGhaMeta } from "/codebuild/output/src3818930399/src/pages/board/notice/[id].vue?macro=true";
import { default as index8IJLLCUpCdMeta } from "/codebuild/output/src3818930399/src/pages/board/notice/index.vue?macro=true";
import { default as payj7P7gyvxBbMeta } from "/codebuild/output/src3818930399/src/pages/callback/pay.vue?macro=true";
import { default as signinDhuS1Qhi0PMeta } from "/codebuild/output/src3818930399/src/pages/callback/signin.vue?macro=true";
import { default as signuprTIY7ZjTZjMeta } from "/codebuild/output/src3818930399/src/pages/callback/signup.vue?macro=true";
import { default as _91postId_93QHVw5QnjIhMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/activities/[postId].vue?macro=true";
import { default as activitiesU1P3c0wPL2Meta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/activities.vue?macro=true";
import { default as _91collectionId_93DlTXO4bv2uMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsuKR5AHHP6UMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/collections.vue?macro=true";
import { default as donationRAoanJ2YCGMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/donation.vue?macro=true";
import { default as guestbookR13XYt9V9UMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/guestbook.vue?macro=true";
import { default as _91postId_93HIqEdZfdD8Meta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/index/[postId].vue?macro=true";
import { default as indexzqG1StrRdoMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/index.vue?macro=true";
import { default as _91noticeId_93e8KKfbWePeMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/notice/[noticeId].vue?macro=true";
import { default as noticewuxtuBsx8YMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/notice.vue?macro=true";
import { default as _91postId_93pYjwwAEtjpMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/[postId].vue?macro=true";
import { default as indexGzGiqLUG5CMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/index.vue?macro=true";
import { default as _91postId_93HUEC1Lo8apMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/posts/[postId].vue?macro=true";
import { default as indexZkTPejviMOMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/posts/index.vue?macro=true";
import { default as rewardN4nxKnpSe6Meta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/reward.vue?macro=true";
import { default as statisticsTj0VO0ol5jMeta } from "/codebuild/output/src3818930399/src/pages/creator/[id]/statistics.vue?macro=true";
import { default as sponsorsOPHQEzyk6sMeta } from "/codebuild/output/src3818930399/src/pages/creator/sponsors.vue?macro=true";
import { default as _91creatorUserSn_93qgFveeJ017Meta } from "/codebuild/output/src3818930399/src/pages/creator/subscribe/cancel/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93scVTrep703Meta } from "/codebuild/output/src3818930399/src/pages/creator/subscribe/opened/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93JGMygAVuyOMeta } from "/codebuild/output/src3818930399/src/pages/creator/subscribe/refund/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93Ho5FRxQk1yMeta } from "/codebuild/output/src3818930399/src/pages/creator/subscribe/support/[creatorUserSn].vue?macro=true";
import { default as allmktlwHlVXTMeta } from "/codebuild/output/src3818930399/src/pages/creators/all.vue?macro=true";
import { default as my2hPTZIACOkMeta } from "/codebuild/output/src3818930399/src/pages/creators/my.vue?macro=true";
import { default as indexBuc4mUE3z8Meta } from "/codebuild/output/src3818930399/src/pages/daily-check/index.vue?macro=true";
import { default as _91id_93McepuFY7LFMeta } from "/codebuild/output/src3818930399/src/pages/feed/[id].vue?macro=true";
import { default as indexVqrx9UoBkmMeta } from "/codebuild/output/src3818930399/src/pages/feed/index.vue?macro=true";
import { default as index9YtaOnnj1uMeta } from "/codebuild/output/src3818930399/src/pages/fund/gaudi-fund/index.vue?macro=true";
import { default as gaudiaJa3ZtzsAXMeta } from "/codebuild/output/src3818930399/src/pages/fund/gaudi.vue?macro=true";
import { default as _91id_93oqNoEcvLr7Meta } from "/codebuild/output/src3818930399/src/pages/fund/history/[id].vue?macro=true";
import { default as historyELv8B91q1fMeta } from "/codebuild/output/src3818930399/src/pages/fund/history.vue?macro=true";
import { default as _91id_93wz9gJHtsNwMeta } from "/codebuild/output/src3818930399/src/pages/fund/index/[id].vue?macro=true";
import { default as indexUfDt1ec0PDMeta } from "/codebuild/output/src3818930399/src/pages/fund/index.vue?macro=true";
import { default as _91id_93qNreoGxSZeMeta } from "/codebuild/output/src3818930399/src/pages/fund/notice/[id].vue?macro=true";
import { default as indexeJHTrbvssqMeta } from "/codebuild/output/src3818930399/src/pages/fund/notice/index.vue?macro=true";
import { default as _91id_936QBElRJS7zMeta } from "/codebuild/output/src3818930399/src/pages/hashtag/[id].vue?macro=true";
import { default as index1r6Ma97HLiMeta } from "/codebuild/output/src3818930399/src/pages/hashtag/index.vue?macro=true";
import { default as indexi8Je0wT6fnMeta } from "/codebuild/output/src3818930399/src/pages/index.vue?macro=true";
import { default as _91id_93djdlulzBJgMeta } from "/codebuild/output/src3818930399/src/pages/nova-plus/[id].vue?macro=true";
import { default as indexKXpYEgwLVvMeta } from "/codebuild/output/src3818930399/src/pages/nova-plus/index.vue?macro=true";
import { default as indexpCmYas1h6nMeta } from "/codebuild/output/src3818930399/src/pages/oulim/index.vue?macro=true";
import { default as _91id_93hbxgSoQVGpMeta } from "/codebuild/output/src3818930399/src/pages/post/[id].vue?macro=true";
import { default as _64_91id_93mwhs7JGl5qMeta } from "/codebuild/output/src3818930399/src/pages/referral/@[id].vue?macro=true";
import { default as _91id_938InGPVtMHaMeta } from "/codebuild/output/src3818930399/src/pages/search/detail/comment/[id].vue?macro=true";
import { default as indexpYj4A0unk3Meta } from "/codebuild/output/src3818930399/src/pages/search/detail/comment/index.vue?macro=true";
import { default as hashtaglYCxNvTeYPMeta } from "/codebuild/output/src3818930399/src/pages/search/detail/hashtag.vue?macro=true";
import { default as _91id_93oFxf3t48BmMeta } from "/codebuild/output/src3818930399/src/pages/search/detail/index/[id].vue?macro=true";
import { default as indexcju51R5y6FMeta } from "/codebuild/output/src3818930399/src/pages/search/detail/index/index.vue?macro=true";
import { default as _91id_93PhsmvRjDdUMeta } from "/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/[id].vue?macro=true";
import { default as index431heF0CgDMeta } from "/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/index.vue?macro=true";
import { default as userSD22JRSm0NMeta } from "/codebuild/output/src3818930399/src/pages/search/detail/user.vue?macro=true";
import { default as _91id_93bEtHphjMC9Meta } from "/codebuild/output/src3818930399/src/pages/search/index/[id].vue?macro=true";
import { default as indexgpk2tFQqTHMeta } from "/codebuild/output/src3818930399/src/pages/search/index.vue?macro=true";
import { default as _91id_93bSPuVwBbcXMeta } from "/codebuild/output/src3818930399/src/pages/terms/[id].vue?macro=true";
import { default as _91postId_93dzkql2U4ADMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/activities/[postId].vue?macro=true";
import { default as activitiespqIhlGApVvMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/activities.vue?macro=true";
import { default as _91collectionId_93aEGrPMnV3UMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionst2fSUb4AATMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/collections.vue?macro=true";
import { default as donationILtC1IgIzgMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/donation.vue?macro=true";
import { default as _91postId_93OgkR3t73JnMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/index/[postId].vue?macro=true";
import { default as indexupUE3RJSciMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/index/index.vue?macro=true";
import { default as rewardVgTTrEe4KWMeta } from "/codebuild/output/src3818930399/src/pages/user/[id]/reward.vue?macro=true";
import { default as indexos5wRl6Ve3Meta } from "/codebuild/output/src3818930399/src/pages/user/sponsors/index.vue?macro=true";
import { default as _91id_93f37LEyGSaQMeta } from "/codebuild/output/src3818930399/src/pages/w3cf/detail/[id].vue?macro=true";
import { default as indexmEjr75c8TAMeta } from "/codebuild/output/src3818930399/src/pages/w3cf/index.vue?macro=true";
export default [
  {
    name: "apply-creator-apply___ko",
    path: "/apply-creator/apply",
    meta: applyjWBrZ9Fnq7Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___en",
    path: "/en/apply-creator/apply",
    meta: applyjWBrZ9Fnq7Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___th",
    path: "/th/apply-creator/apply",
    meta: applyjWBrZ9Fnq7Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator___ko",
    path: "/apply-creator",
    meta: indexBGJLk2OvRcMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___en",
    path: "/en/apply-creator",
    meta: indexBGJLk2OvRcMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___th",
    path: "/th/apply-creator",
    meta: indexBGJLk2OvRcMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-rent-facility-id-additional___ko",
    path: "/apply-rent/facility-:id()/additional",
    meta: additionalD319DhyUHcMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___en",
    path: "/en/apply-rent/facility-:id()/additional",
    meta: additionalD319DhyUHcMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___th",
    path: "/th/apply-rent/facility-:id()/additional",
    meta: additionalD319DhyUHcMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-complete___ko",
    path: "/apply-rent/facility-:id()/complete",
    meta: completeP0zGhBmsfkMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___en",
    path: "/en/apply-rent/facility-:id()/complete",
    meta: completeP0zGhBmsfkMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___th",
    path: "/th/apply-rent/facility-:id()/complete",
    meta: completeP0zGhBmsfkMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id___ko",
    path: "/apply-rent/facility-:id()",
    meta: indexfaIpwtNrPfMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___en",
    path: "/en/apply-rent/facility-:id()",
    meta: indexfaIpwtNrPfMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___th",
    path: "/th/apply-rent/facility-:id()",
    meta: indexfaIpwtNrPfMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent___ko",
    path: "/apply-rent",
    meta: indexxYU3aDU5S3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___en",
    path: "/en/apply-rent",
    meta: indexxYU3aDU5S3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___th",
    path: "/th/apply-rent",
    meta: indexxYU3aDU5S3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-super-moon-form___ko",
    path: "/apply-super-moon/form",
    meta: form7kTG9FShSXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___en",
    path: "/en/apply-super-moon/form",
    meta: form7kTG9FShSXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___th",
    path: "/th/apply-super-moon/form",
    meta: form7kTG9FShSXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon___ko",
    path: "/apply-super-moon",
    meta: index29oyyjOIIUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___en",
    path: "/en/apply-super-moon",
    meta: index29oyyjOIIUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___th",
    path: "/th/apply-super-moon",
    meta: index29oyyjOIIUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "board-event-id___ko",
    path: "/board/event/:id()",
    meta: _91id_93a4iRZ2aRqjMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___en",
    path: "/en/board/event/:id()",
    meta: _91id_93a4iRZ2aRqjMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___th",
    path: "/th/board/event/:id()",
    meta: _91id_93a4iRZ2aRqjMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event___ko",
    path: "/board/event",
    meta: indexPwz4aQH54iMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___en",
    path: "/en/board/event",
    meta: indexPwz4aQH54iMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___th",
    path: "/th/board/event",
    meta: indexPwz4aQH54iMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/event/index.vue")
  },
  {
    name: "board-faq-id___ko",
    path: "/board/faq/:id()",
    meta: _91id_93SzLF7hN3r1Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___en",
    path: "/en/board/faq/:id()",
    meta: _91id_93SzLF7hN3r1Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___th",
    path: "/th/board/faq/:id()",
    meta: _91id_93SzLF7hN3r1Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq___ko",
    path: "/board/faq",
    meta: indexTXPJf4Kvv3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___en",
    path: "/en/board/faq",
    meta: indexTXPJf4Kvv3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___th",
    path: "/th/board/faq",
    meta: indexTXPJf4Kvv3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/faq/index.vue")
  },
  {
    name: "board-news-id___ko",
    path: "/board/news/:id()",
    meta: _91id_93pfH2VWjbrdMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___en",
    path: "/en/board/news/:id()",
    meta: _91id_93pfH2VWjbrdMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___th",
    path: "/th/board/news/:id()",
    meta: _91id_93pfH2VWjbrdMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news___ko",
    path: "/board/news",
    meta: indexGOUezlqIjUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___en",
    path: "/en/board/news",
    meta: indexGOUezlqIjUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___th",
    path: "/th/board/news",
    meta: indexGOUezlqIjUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/news/index.vue")
  },
  {
    name: "board-notice-id___ko",
    path: "/board/notice/:id()",
    meta: _91id_93rPkMlxVGhaMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___en",
    path: "/en/board/notice/:id()",
    meta: _91id_93rPkMlxVGhaMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___th",
    path: "/th/board/notice/:id()",
    meta: _91id_93rPkMlxVGhaMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice___ko",
    path: "/board/notice",
    meta: index8IJLLCUpCdMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___en",
    path: "/en/board/notice",
    meta: index8IJLLCUpCdMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___th",
    path: "/th/board/notice",
    meta: index8IJLLCUpCdMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/board/notice/index.vue")
  },
  {
    name: "callback-pay___ko",
    path: "/callback/pay",
    meta: payj7P7gyvxBbMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___en",
    path: "/en/callback/pay",
    meta: payj7P7gyvxBbMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___th",
    path: "/th/callback/pay",
    meta: payj7P7gyvxBbMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/pay.vue")
  },
  {
    name: "callback-signin___ko",
    path: "/callback/signin",
    meta: signinDhuS1Qhi0PMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___en",
    path: "/en/callback/signin",
    meta: signinDhuS1Qhi0PMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___th",
    path: "/th/callback/signin",
    meta: signinDhuS1Qhi0PMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signup___ko",
    path: "/callback/signup",
    meta: signuprTIY7ZjTZjMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___en",
    path: "/en/callback/signup",
    meta: signuprTIY7ZjTZjMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___th",
    path: "/th/callback/signup",
    meta: signuprTIY7ZjTZjMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/callback/signup.vue")
  },
  {
    name: "creator-id-activities___ko",
    path: "/creator/:id()/activities",
    meta: activitiesU1P3c0wPL2Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93QHVw5QnjIhMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___en",
    path: "/en/creator/:id()/activities",
    meta: activitiesU1P3c0wPL2Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93QHVw5QnjIhMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___th",
    path: "/th/creator/:id()/activities",
    meta: activitiesU1P3c0wPL2Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93QHVw5QnjIhMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-collections___ko",
    path: "/creator/:id()/collections",
    meta: collectionsuKR5AHHP6UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93DlTXO4bv2uMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___en",
    path: "/en/creator/:id()/collections",
    meta: collectionsuKR5AHHP6UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93DlTXO4bv2uMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___th",
    path: "/th/creator/:id()/collections",
    meta: collectionsuKR5AHHP6UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93DlTXO4bv2uMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-donation___ko",
    path: "/creator/:id()/donation",
    meta: donationRAoanJ2YCGMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___en",
    path: "/en/creator/:id()/donation",
    meta: donationRAoanJ2YCGMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___th",
    path: "/th/creator/:id()/donation",
    meta: donationRAoanJ2YCGMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-guestbook___ko",
    path: "/creator/:id()/guestbook",
    meta: guestbookR13XYt9V9UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___en",
    path: "/en/creator/:id()/guestbook",
    meta: guestbookR13XYt9V9UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___th",
    path: "/th/creator/:id()/guestbook",
    meta: guestbookR13XYt9V9UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id___ko",
    path: "/creator/:id()",
    meta: indexzqG1StrRdoMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___ko",
    path: ":postId()",
    meta: _91postId_93HIqEdZfdD8Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___en",
    path: "/en/creator/:id()",
    meta: indexzqG1StrRdoMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___en",
    path: ":postId()",
    meta: _91postId_93HIqEdZfdD8Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___th",
    path: "/th/creator/:id()",
    meta: indexzqG1StrRdoMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___th",
    path: ":postId()",
    meta: _91postId_93HIqEdZfdD8Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id-notice___ko",
    path: "/creator/:id()/notice",
    meta: noticewuxtuBsx8YMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___ko",
    path: ":noticeId()",
    meta: _91noticeId_93e8KKfbWePeMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___en",
    path: "/en/creator/:id()/notice",
    meta: noticewuxtuBsx8YMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___en",
    path: ":noticeId()",
    meta: _91noticeId_93e8KKfbWePeMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___th",
    path: "/th/creator/:id()/notice",
    meta: noticewuxtuBsx8YMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___th",
    path: ":noticeId()",
    meta: _91noticeId_93e8KKfbWePeMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-nova-plus-postId___ko",
    path: "/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93pYjwwAEtjpMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___en",
    path: "/en/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93pYjwwAEtjpMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___th",
    path: "/th/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93pYjwwAEtjpMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus___ko",
    path: "/creator/:id()/nova-plus",
    meta: indexGzGiqLUG5CMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___en",
    path: "/en/creator/:id()/nova-plus",
    meta: indexGzGiqLUG5CMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___th",
    path: "/th/creator/:id()/nova-plus",
    meta: indexGzGiqLUG5CMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-posts-postId___ko",
    path: "/creator/:id()/posts/:postId()",
    meta: _91postId_93HUEC1Lo8apMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___en",
    path: "/en/creator/:id()/posts/:postId()",
    meta: _91postId_93HUEC1Lo8apMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___th",
    path: "/th/creator/:id()/posts/:postId()",
    meta: _91postId_93HUEC1Lo8apMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts___ko",
    path: "/creator/:id()/posts",
    meta: indexZkTPejviMOMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___en",
    path: "/en/creator/:id()/posts",
    meta: indexZkTPejviMOMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___th",
    path: "/th/creator/:id()/posts",
    meta: indexZkTPejviMOMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-reward___ko",
    path: "/creator/:id()/reward",
    meta: rewardN4nxKnpSe6Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___en",
    path: "/en/creator/:id()/reward",
    meta: rewardN4nxKnpSe6Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___th",
    path: "/th/creator/:id()/reward",
    meta: rewardN4nxKnpSe6Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-statistics___ko",
    path: "/creator/:id()/statistics",
    meta: statisticsTj0VO0ol5jMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___en",
    path: "/en/creator/:id()/statistics",
    meta: statisticsTj0VO0ol5jMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___th",
    path: "/th/creator/:id()/statistics",
    meta: statisticsTj0VO0ol5jMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-sponsors___ko",
    path: "/creator/sponsors",
    meta: sponsorsOPHQEzyk6sMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___en",
    path: "/en/creator/sponsors",
    meta: sponsorsOPHQEzyk6sMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___th",
    path: "/th/creator/sponsors",
    meta: sponsorsOPHQEzyk6sMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___ko",
    path: "/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93qgFveeJ017Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___en",
    path: "/en/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93qgFveeJ017Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___th",
    path: "/th/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93qgFveeJ017Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___ko",
    path: "/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93scVTrep703Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___en",
    path: "/en/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93scVTrep703Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___th",
    path: "/th/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93scVTrep703Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___ko",
    path: "/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93JGMygAVuyOMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___en",
    path: "/en/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93JGMygAVuyOMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___th",
    path: "/th/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93JGMygAVuyOMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___ko",
    path: "/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93Ho5FRxQk1yMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___en",
    path: "/en/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93Ho5FRxQk1yMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___th",
    path: "/th/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93Ho5FRxQk1yMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creators-all___ko",
    path: "/creators/all",
    meta: allmktlwHlVXTMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___en",
    path: "/en/creators/all",
    meta: allmktlwHlVXTMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___th",
    path: "/th/creators/all",
    meta: allmktlwHlVXTMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creators/all.vue")
  },
  {
    name: "creators-my___ko",
    path: "/creators/my",
    meta: my2hPTZIACOkMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___en",
    path: "/en/creators/my",
    meta: my2hPTZIACOkMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___th",
    path: "/th/creators/my",
    meta: my2hPTZIACOkMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/creators/my.vue")
  },
  {
    name: "daily-check___ko",
    path: "/daily-check",
    meta: indexBuc4mUE3z8Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___en",
    path: "/en/daily-check",
    meta: indexBuc4mUE3z8Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___th",
    path: "/th/daily-check",
    meta: indexBuc4mUE3z8Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/daily-check/index.vue")
  },
  {
    name: "feed-id___ko",
    path: "/feed/:id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___en",
    path: "/en/feed/:id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___th",
    path: "/th/feed/:id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/feed/[id].vue")
  },
  {
    name: "feed___ko",
    path: "/feed",
    component: () => import("/codebuild/output/src3818930399/src/pages/feed/index.vue")
  },
  {
    name: "feed___en",
    path: "/en/feed",
    component: () => import("/codebuild/output/src3818930399/src/pages/feed/index.vue")
  },
  {
    name: "feed___th",
    path: "/th/feed",
    component: () => import("/codebuild/output/src3818930399/src/pages/feed/index.vue")
  },
  {
    name: "fund-gaudi-fund___ko",
    path: "/fund/gaudi-fund",
    meta: index9YtaOnnj1uMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___en",
    path: "/en/fund/gaudi-fund",
    meta: index9YtaOnnj1uMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___th",
    path: "/th/fund/gaudi-fund",
    meta: index9YtaOnnj1uMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi___ko",
    path: "/fund/gaudi",
    meta: gaudiaJa3ZtzsAXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___en",
    path: "/en/fund/gaudi",
    meta: gaudiaJa3ZtzsAXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___th",
    path: "/th/fund/gaudi",
    meta: gaudiaJa3ZtzsAXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-history___ko",
    path: "/fund/history",
    meta: historyELv8B91q1fMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___en",
    path: "/en/fund/history",
    meta: historyELv8B91q1fMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___th",
    path: "/th/fund/history",
    meta: historyELv8B91q1fMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund___ko",
    path: "/fund",
    meta: indexUfDt1ec0PDMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___en",
    path: "/en/fund",
    meta: indexUfDt1ec0PDMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___th",
    path: "/th/fund",
    meta: indexUfDt1ec0PDMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund-notice-id___ko",
    path: "/fund/notice/:id()",
    meta: _91id_93qNreoGxSZeMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___en",
    path: "/en/fund/notice/:id()",
    meta: _91id_93qNreoGxSZeMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___th",
    path: "/th/fund/notice/:id()",
    meta: _91id_93qNreoGxSZeMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice___ko",
    path: "/fund/notice",
    meta: indexeJHTrbvssqMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___en",
    path: "/en/fund/notice",
    meta: indexeJHTrbvssqMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___th",
    path: "/th/fund/notice",
    meta: indexeJHTrbvssqMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/fund/notice/index.vue")
  },
  {
    name: "hashtag-id___ko",
    path: "/hashtag/:id()",
    meta: _91id_936QBElRJS7zMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___en",
    path: "/en/hashtag/:id()",
    meta: _91id_936QBElRJS7zMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___th",
    path: "/th/hashtag/:id()",
    meta: _91id_936QBElRJS7zMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag___ko",
    path: "/hashtag",
    meta: index1r6Ma97HLiMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___en",
    path: "/en/hashtag",
    meta: index1r6Ma97HLiMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___th",
    path: "/th/hashtag",
    meta: index1r6Ma97HLiMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/hashtag/index.vue")
  },
  {
    name: "index___ko",
    path: "/",
    meta: indexi8Je0wT6fnMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexi8Je0wT6fnMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/index.vue")
  },
  {
    name: "index___th",
    path: "/th",
    meta: indexi8Je0wT6fnMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/index.vue")
  },
  {
    name: "nova-plus-id___ko",
    path: "/nova-plus/:id()",
    meta: _91id_93djdlulzBJgMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___en",
    path: "/en/nova-plus/:id()",
    meta: _91id_93djdlulzBJgMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___th",
    path: "/th/nova-plus/:id()",
    meta: _91id_93djdlulzBJgMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus___ko",
    path: "/nova-plus",
    meta: indexKXpYEgwLVvMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___en",
    path: "/en/nova-plus",
    meta: indexKXpYEgwLVvMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___th",
    path: "/th/nova-plus",
    meta: indexKXpYEgwLVvMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/nova-plus/index.vue")
  },
  {
    name: "oulim___ko",
    path: "/oulim",
    meta: indexpCmYas1h6nMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___en",
    path: "/en/oulim",
    meta: indexpCmYas1h6nMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___th",
    path: "/th/oulim",
    meta: indexpCmYas1h6nMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/oulim/index.vue")
  },
  {
    name: "post-id___ko",
    path: "/post/:id()",
    meta: _91id_93hbxgSoQVGpMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/post/[id].vue")
  },
  {
    name: "post-id___en",
    path: "/en/post/:id()",
    meta: _91id_93hbxgSoQVGpMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/post/[id].vue")
  },
  {
    name: "post-id___th",
    path: "/th/post/:id()",
    meta: _91id_93hbxgSoQVGpMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/post/[id].vue")
  },
  {
    name: "referral-@id___ko",
    path: "/referral/@:id()",
    meta: _64_91id_93mwhs7JGl5qMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___en",
    path: "/en/referral/@:id()",
    meta: _64_91id_93mwhs7JGl5qMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___th",
    path: "/th/referral/@:id()",
    meta: _64_91id_93mwhs7JGl5qMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/referral/@[id].vue")
  },
  {
    name: "search-detail-comment-id___ko",
    path: "/search/detail/comment/:id()",
    meta: _91id_938InGPVtMHaMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___en",
    path: "/en/search/detail/comment/:id()",
    meta: _91id_938InGPVtMHaMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___th",
    path: "/th/search/detail/comment/:id()",
    meta: _91id_938InGPVtMHaMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment___ko",
    path: "/search/detail/comment",
    meta: indexpYj4A0unk3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___en",
    path: "/en/search/detail/comment",
    meta: indexpYj4A0unk3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___th",
    path: "/th/search/detail/comment",
    meta: indexpYj4A0unk3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-hashtag___ko",
    path: "/search/detail/hashtag",
    meta: hashtaglYCxNvTeYPMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___en",
    path: "/en/search/detail/hashtag",
    meta: hashtaglYCxNvTeYPMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___th",
    path: "/th/search/detail/hashtag",
    meta: hashtaglYCxNvTeYPMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-index-id___ko",
    path: "/search/detail/:id()",
    meta: _91id_93oFxf3t48BmMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___en",
    path: "/en/search/detail/:id()",
    meta: _91id_93oFxf3t48BmMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___th",
    path: "/th/search/detail/:id()",
    meta: _91id_93oFxf3t48BmMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index___ko",
    path: "/search/detail",
    meta: indexcju51R5y6FMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___en",
    path: "/en/search/detail",
    meta: indexcju51R5y6FMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___th",
    path: "/th/search/detail",
    meta: indexcju51R5y6FMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-nova-plus-id___ko",
    path: "/search/detail/nova-plus/:id()",
    meta: _91id_93PhsmvRjDdUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___en",
    path: "/en/search/detail/nova-plus/:id()",
    meta: _91id_93PhsmvRjDdUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___th",
    path: "/th/search/detail/nova-plus/:id()",
    meta: _91id_93PhsmvRjDdUMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus___ko",
    path: "/search/detail/nova-plus",
    meta: index431heF0CgDMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___en",
    path: "/en/search/detail/nova-plus",
    meta: index431heF0CgDMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___th",
    path: "/th/search/detail/nova-plus",
    meta: index431heF0CgDMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-user___ko",
    path: "/search/detail/user",
    meta: userSD22JRSm0NMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___en",
    path: "/en/search/detail/user",
    meta: userSD22JRSm0NMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___th",
    path: "/th/search/detail/user",
    meta: userSD22JRSm0NMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/detail/user.vue")
  },
  {
    name: "search___ko",
    path: "/search",
    meta: indexgpk2tFQqTHMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___ko",
    path: ":id()",
    meta: _91id_93bEtHphjMC9Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexgpk2tFQqTHMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___en",
    path: ":id()",
    meta: _91id_93bEtHphjMC9Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___th",
    path: "/th/search",
    meta: indexgpk2tFQqTHMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___th",
    path: ":id()",
    meta: _91id_93bEtHphjMC9Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "terms-id___ko",
    path: "/terms/:id()",
    meta: _91id_93bSPuVwBbcXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___en",
    path: "/en/terms/:id()",
    meta: _91id_93bSPuVwBbcXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___th",
    path: "/th/terms/:id()",
    meta: _91id_93bSPuVwBbcXMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/terms/[id].vue")
  },
  {
    name: "user-id-activities___ko",
    path: "/user/:id()/activities",
    meta: activitiespqIhlGApVvMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93dzkql2U4ADMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___en",
    path: "/en/user/:id()/activities",
    meta: activitiespqIhlGApVvMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93dzkql2U4ADMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___th",
    path: "/th/user/:id()/activities",
    meta: activitiespqIhlGApVvMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93dzkql2U4ADMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-collections___ko",
    path: "/user/:id()/collections",
    meta: collectionst2fSUb4AATMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93aEGrPMnV3UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___en",
    path: "/en/user/:id()/collections",
    meta: collectionst2fSUb4AATMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93aEGrPMnV3UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___th",
    path: "/th/user/:id()/collections",
    meta: collectionst2fSUb4AATMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93aEGrPMnV3UMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-donation___ko",
    path: "/user/:id()/donation",
    meta: donationILtC1IgIzgMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___en",
    path: "/en/user/:id()/donation",
    meta: donationILtC1IgIzgMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___th",
    path: "/th/user/:id()/donation",
    meta: donationILtC1IgIzgMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-index-postId___ko",
    path: "/user/:id()/:postId()",
    meta: _91postId_93OgkR3t73JnMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___en",
    path: "/en/user/:id()/:postId()",
    meta: _91postId_93OgkR3t73JnMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___th",
    path: "/th/user/:id()/:postId()",
    meta: _91postId_93OgkR3t73JnMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index___ko",
    path: "/user/:id()",
    meta: indexupUE3RJSciMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___en",
    path: "/en/user/:id()",
    meta: indexupUE3RJSciMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___th",
    path: "/th/user/:id()",
    meta: indexupUE3RJSciMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-reward___ko",
    path: "/user/:id()/reward",
    meta: rewardVgTTrEe4KWMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___en",
    path: "/en/user/:id()/reward",
    meta: rewardVgTTrEe4KWMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___th",
    path: "/th/user/:id()/reward",
    meta: rewardVgTTrEe4KWMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-sponsors___ko",
    path: "/user/sponsors",
    meta: indexos5wRl6Ve3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___en",
    path: "/en/user/sponsors",
    meta: indexos5wRl6Ve3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___th",
    path: "/th/user/sponsors",
    meta: indexos5wRl6Ve3Meta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/user/sponsors/index.vue")
  },
  {
    name: "w3cf-detail-id___ko",
    path: "/w3cf/detail/:id()",
    meta: _91id_93f37LEyGSaQMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___en",
    path: "/en/w3cf/detail/:id()",
    meta: _91id_93f37LEyGSaQMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___th",
    path: "/th/w3cf/detail/:id()",
    meta: _91id_93f37LEyGSaQMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf___ko",
    path: "/w3cf",
    meta: indexmEjr75c8TAMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___en",
    path: "/en/w3cf",
    meta: indexmEjr75c8TAMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___th",
    path: "/th/w3cf",
    meta: indexmEjr75c8TAMeta || {},
    component: () => import("/codebuild/output/src3818930399/src/pages/w3cf/index.vue")
  }
]